@charset "utf-8";

$minWidthSize: 10;
$maxWidthSize: 1000;
$widthSizeStep: 5;
// ループを使用してCSSを生成
@for $i from 0 through floor(($maxWidthSize - $minWidthSize) / $widthSizeStep) {
  // 各ループで使用する値を計算
  $value: $minWidthSize + ($i * $widthSizeStep);
  // 生成されるCSS
  .widthSize#{$value} {
    width: $value * 1px;
  }
  .widthSize#{$value}-mdMin {
    @include mq-pc {
      width: $value * 1px;
    }
  }
  .widthSize#{$value}-mdMax {
    @include mq-sp {
      width: $value * 1px;
    }
  }
}
