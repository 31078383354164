@charset "utf-8";

.imgMax {
  width: 100%;
  height: auto;
//  &PC {
//    @include mq-pc {
//      width: 100%;
//      height: auto;
//    }
//  }
}
 .imgMaxW {
   max-width: 100%;
   height: auto;
 }
