@charset "utf-8";

.disNone {
  display: none;
}
.disNone-mdMin {
  @include mq-pc {
    display: none;
  }
}
.disNone-mdMax {
  @include mq-sp {
    display: none;
  }
}
