@charset "utf-8";

//Media Queries
@mixin mq-sp($max: $sp-max-width) { //767px以下
  @media only screen and (max-width: #{$max}) {
    @content;
  }
}
@mixin mq-sp2($max: 360px) { //360px以下
  @media only screen and (max-width: #{$max}) {
    @content;
  }
}
@mixin mq-pc($min: $pc-min-width) { //768px以上
  @media only screen and (min-width: #{$min}) {
    @content;
  }
}
@mixin mq-pcMax($min: $pc-min-width,$max: $pc-max-width) { //768px以上 1039px以下
  @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}
@mixin mq-pc2($min: $pc2-min-width) { //1040px以上
  @media only screen and (min-width: #{$min}) {
    @content;
  }
}
@mixin mq-pc2Max($min: $pc-min-width,$max: $pc2-max-width) { //768px以上 1200px以下
  @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}
@mixin mq-pc3($min: $pc3-min-width) { //1201px以上
  @media only screen and (min-width: #{$min}) {
    @content;
  }
}
@mixin mq-freeAbove($min) {
  @media only screen and (min-width: #{$min}) {
    @content;
  }
}
@mixin mq-freeBelow($max) {
  @media only screen and (max-width: #{$max}) {
    @content;
  }
}
//@mixin mq-sp2($max: $sp2-max-width) { //1059px以下
//  @media only screen and (max-width: #{$max}) {
//    @content;
//  }
//}
//@mixin mq-pc2($min: $pc2-min-width) { //1060px以上
//  @media only screen and (min-width: #{$min}) {
//    @content;
//  }
//}
//@mixin mq-sp3($max: $sp3-max-width) { //1259px以下
//  @media only screen and (max-width: #{$max}) {
//    @content;
//  }
//}
//@mixin mq-pc3($min: $pc3-min-width) { //1260px以上
//  @media only screen and (min-width: #{$min}) {
//    @content;
//  }
//}
//@mixin mq-subPC($min: $pc-min-width,$max: $subPC-max-width) { //768px 以上 1059px以下
//  @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
//    @content;
//  }
//}
//@mixin mq-subPC2($min: $pc-min-width,$max: $subPC2-max-width) { //768px 以上 1259px以下
//  @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
//    @content;
//  }
//}
//@mixin mq-subPC3($min: $pc-min-width,$max: $subPC3-max-width) { //768px 以上 1359px以下
//  @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
//    @content;
//  }
//}
//@mixin mq-free($min: $pc-min-width,$max: $subPC3-max-width) {
//  @if $min == none {
//    @media only screen and (max-width: #{$max}) {
//      @content;
//    }
//  } @else {
//    @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
//      @content;
//    }
//  }
//}


//hack
//@mixin IE() {
//  @media all and (-ms-high-contrast: none) {// IE10以上
//    @content;
//  }
//}
//@mixin IE-sp($max: $sp-max-width) {
//  @media all and (max-width: #{$max}) and (-ms-high-contrast: none) {// IE10以上 SPサイズのみ
//    @content;
//  }
//}
//@mixin Edge() {
//  @supports (-ms-ime-align:auto){
//    @content;
//  }
//}

//userAgent
@mixin pc() {
  .desktop & {
    @content;
  }
}
@mixin sp() {
  .smartphone & {
    @content;
  }
}
@mixin tab() {
  .tablet & {
    @content;
  }
}

//　メインのカラムサイズ
@mixin column($width:$base-content-width) {
  flex: 1 1 $width;
  margin: 0 auto;
  padding: 0 $space-unit;
  @include mq-pc {
    max-width: $width;
  }
  @include mq-sp {
    padding: 0 $space-unit-sp;
  }
}

//letter spacing　調整用
@mixin letterSpace-short() {
  letter-spacing: .05em;
}
@mixin letterSpace-normal() {
  letter-spacing: .1em;
}
@mixin letterSpace-long() {
  letter-spacing: .15em;
}

//fontsファミリー
@mixin font-gothic() {
  font-family: 'Epilogue', 'Lucida Grande', 'Lucida Bright', 'Georgia', sans-serif;
}
@mixin font-mincho() {
  font-family: 'Lustria', serif;
}

//矢印
@mixin arrow($width: 0,$height: 0,$width-sp: 0,$height-sp: 0,$color: $color-black,$border: 5px) {
  display: inline-block;
  width: $width;
  height: $height;
  border-style: solid;
  border-width: $border 0 $border $border*2;
  border-color: transparent transparent transparent $color;
  @include mq-sp {
    width: $width-sp;
    height: $height-sp;
  }
}

//カラム

@mixin oneColumn($space:$box-space) {
  $root: &;
  flex: 1 1 100%;
  max-width: 100%;
  margin-right: 0;
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin halfColumn($space:$box-space,$space-bottom:$box-space) {
  $root: &;
  flex: 1 1 calc(50% - #{$space/2});
  max-width: calc(50% - #{$space/2});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(2n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@mixin tripartitionColumn($space:$box-space,$space-bottom:$box-space) {
  $root: &;
  flex: 1 1 calc(33.33% - #{$space*2/3});
  max-width: calc(33.33% - #{$space*2/3});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(3n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(3n+2),
  &:nth-last-child(2)#{$root}:nth-child(3n+1),
  &:nth-last-child(3)#{$root}:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@mixin quarterColumn($space:$box-space,$space-bottom:$box-space) {
  $root: &;
  flex: 1 1 calc(25.00% - #{$space*3/4});
  max-width: calc(25.00% - #{$space*3/4});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(4n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(4n+1),
  &:nth-last-child(2)#{$root}:nth-child(4n+2),
  &:nth-last-child(2)#{$root}:nth-child(4n+3),
  &:nth-last-child(3)#{$root}:nth-child(4n+1),
  &:nth-last-child(3)#{$root}:nth-child(4n+2),
  &:nth-last-child(4)#{$root}:nth-child(4n+1) {
    margin-bottom: 0;
  }
}

@mixin fifthColumn($space:$box-space,$space-bottom:$box-space) {
  $root: &;
  flex: 1 1 calc(20.00% - #{$space*4/5});
  max-width: calc(20.00% - #{$space*4/5});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(5n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(5n+1),
  &:nth-last-child(2)#{$root}:nth-child(5n+2),
  &:nth-last-child(2)#{$root}:nth-child(5n+3),
  &:nth-last-child(2)#{$root}:nth-child(5n+4),
  &:nth-last-child(3)#{$root}:nth-child(5n+1),
  &:nth-last-child(3)#{$root}:nth-child(5n+2),
  &:nth-last-child(3)#{$root}:nth-child(5n+3),
  &:nth-last-child(4)#{$root}:nth-child(5n+1),
  &:nth-last-child(4)#{$root}:nth-child(5n+2),
  &:nth-last-child(5)#{$root}:nth-child(5n+1) {
    margin-bottom: 0;
  }
}

@mixin sixthColumn($space:$box-space,$space-bottom:$box-space) {
  $root: &;
  flex: 1 1 calc(16.66% - #{$space*5/6});
  max-width: calc(16.66% - #{$space*5/6});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(6n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(6n+1),
  &:nth-last-child(2)#{$root}:nth-child(6n+2),
  &:nth-last-child(2)#{$root}:nth-child(6n+3),
  &:nth-last-child(2)#{$root}:nth-child(6n+4),
  &:nth-last-child(2)#{$root}:nth-child(6n+5),
  &:nth-last-child(3)#{$root}:nth-child(6n+1),
  &:nth-last-child(3)#{$root}:nth-child(6n+2),
  &:nth-last-child(3)#{$root}:nth-child(6n+3),
  &:nth-last-child(3)#{$root}:nth-child(6n+4),
  &:nth-last-child(4)#{$root}:nth-child(6n+1),
  &:nth-last-child(4)#{$root}:nth-child(6n+2),
  &:nth-last-child(4)#{$root}:nth-child(6n+3),
  &:nth-last-child(5)#{$root}:nth-child(6n+1),
  &:nth-last-child(5)#{$root}:nth-child(6n+2),
  &:nth-last-child(6)#{$root}:nth-child(6n+1) {
    margin-bottom: 0;
  }
}

@mixin cf() {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
}

//@mixin arrow2($width: 9px,$height: 9px,$color: $color-black,$rorate: 45deg,$translateY: 0,$border: 1px) {
//  overflow: hidden;
//  position: relative;
//  font-size: 0;
//  padding: 0;
//  transition: .3s;
//  display: inline-block;
//  width: $width;
//  height: $height;
//  border: $border solid;
//  border-color: $color $color transparent transparent;
//  transform: translateY($translateY) rotate($rorate);
//  &::after {
//    position: absolute;
//    right: 0;
//    top: -1px;
//    margin: auto;
//    content: "";
//    height: 1px;
//    width: 10px;
//    transform: rotate(-45deg);
//    transform-origin: right top;
//    background-color: $color-black;
//  }
//}

//グラデーション
/**/
//$base-gradient: linear-gradient(-22deg, $color-gradOrange1 0%, $color-gradOrange2 25%, $color-gradOrange3 50%, $color-gradOrange4 75%, $color-gradOrange5 100%);
//@mixin bg-grad() {
//  background-color: $color-base;
//  background-image: $base-gradient;
//  background-position: center;
//  background-size: 250% 100%;
//}
//$base-instagram: linear-gradient(225deg,$color-instagram 0%,#f50000 100%);
//@mixin bg-grad-instagram() {
//  background-color: $color-instagram;
//  background-image: $base-instagram;
//  background-position: center;
//  background-size: 100% 100%;
//}

//テキストグラデーション
//@mixin text-grad() {
//  @include bg-grad;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//}
//@mixin text-grad-instagram() {
//  @include bg-grad-instagram;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//}

//ここまで
//@mixin border-grad($width: 1px) {
//  border-left: $width solid $color-gradOrange1;
//  border-right: $width solid $color-gradOrange5;
//  border-bottom: $width solid;
//  border-top: $width solid;
//  border-image: $base-gradient;
//  border-image-slice: 1;
//}

//三角
//@mixin triangle($width: 5px,$color: $color-black,$rorate: 0deg) {
//  display: inline-block;
//  width: 0;
//  height: 0;
//  border-style: solid;
//  border-width: $width 0 $width $width*1.732;
//  border-color: transparent transparent transparent $color;
//  transform: rotate($rorate);
//}
/////
@mixin halfColumnCenter() {
  > * {
    grid-column: span 2;
    //1つの場合
    &:nth-last-child(1):nth-child(2n+1) {
      grid-column: 2 / 4;
    }
  }
}
@mixin tripartitionColumnCenter() {
  > * {
    grid-column: span 2;
    //1つの場合
    &:nth-last-child(1):nth-child(3n+1) {
      grid-column: 3 / 5;
    }
    //2つの場合
    &:nth-last-child(2):nth-child(3n+1) {
      grid-column: 2 / 4;
    }
    &:nth-last-child(1):nth-child(3n+2) {
      grid-column: 4 / 6;
    }
  }
}
@mixin quarterColumnCenter() {
  > * {
    grid-column: span 2;
    //1つの場合
    &:nth-last-child(1):nth-child(4n+1) {
      grid-column: 4 / 6;
    }
    //2つの場合
    &:nth-last-child(2):nth-child(4n+1) {
      grid-column: 3 / 5;
    }
    &:nth-last-child(1):nth-child(4n+2) {
      grid-column: 5 / 7;
    }
    //3つの場合
    &:nth-last-child(3):nth-child(4n+1) {
      grid-column: 2 / 4;
    }
    &:nth-last-child(2):nth-child(4n+2) {
      grid-column: 4 / 6;
    }
    &:nth-last-child(1):nth-child(4n+3) {
      grid-column: 6 / 8;
    }
  }
}
@mixin fifthColumnCenter() {
  > * {
    grid-column: span 2;
    //1つの場合
    &:nth-last-child(1):nth-child(5n+1) {
      grid-column: 5 / 7;
    }
    //2つの場合
    &:nth-last-child(2):nth-child(5n+1) {
      grid-column: 4 / 6;
    }
    &:nth-last-child(1):nth-child(5n+2) {
      grid-column: 6 / 8;
    }
    //3つの場合
    &:nth-last-child(3):nth-child(5n+1) {
      grid-column: 3 / 5;
    }
    &:nth-last-child(2):nth-child(5n+2) {
      grid-column: 5 / 7;
    }
    &:nth-last-child(1):nth-child(5n+3) {
      grid-column: 7 / 9;
    }
    //4つの場合
    &:nth-last-child(4):nth-child(5n+1) {
      grid-column: 2 / 4;
    }
    &:nth-last-child(3):nth-child(5n+2) {
      grid-column: 4 / 6;
    }
    &:nth-last-child(2):nth-child(5n+3) {
      grid-column: 6 / 8;
    }
    &:nth-last-child(1):nth-child(5n+4) {
      grid-column: 8 / 10;
    }
  }
}
@mixin sixthColumnCenter() {
  > * {
    grid-column: span 2;
    //1つの場合
    &:nth-last-child(1):nth-child(6n+1) {
      grid-column: 6 / 8;
    }
    //2つの場合
    &:nth-last-child(2):nth-child(6n+1) {
      grid-column: 5 / 7;
    }
    &:nth-last-child(1):nth-child(6n+2) {
      grid-column: 7 / 9;
    }
    //3つの場合
    &:nth-last-child(3):nth-child(6n+1) {
      grid-column: 4 / 6;
    }
    &:nth-last-child(2):nth-child(6n+2) {
      grid-column: 6 / 8;
    }
    &:nth-last-child(1):nth-child(6n+3) {
      grid-column: 8 / 10;
    }
    //4つの場合
    &:nth-last-child(4):nth-child(6n+1) {
      grid-column: 3 / 5;
    }
    &:nth-last-child(3):nth-child(6n+2) {
      grid-column: 5 / 7;
    }
    &:nth-last-child(2):nth-child(6n+3) {
      grid-column: 7 / 9;
    }
    &:nth-last-child(1):nth-child(6n+4) {
      grid-column: 9 / 11;
    }
    //5つの場合
    &:nth-last-child(5):nth-child(6n+1) {
      grid-column: 2 / 4;
    }
    &:nth-last-child(4):nth-child(6n+2) {
      grid-column: 4 / 6;
    }
    &:nth-last-child(3):nth-child(6n+3) {
      grid-column: 6 / 8;
    }
    &:nth-last-child(2):nth-child(6n+4) {
      grid-column: 8 / 10;
    }
    &:nth-last-child(1):nth-child(6n+5) {
      grid-column: 10 / 12;
    }
  }
}
