@charset "utf-8";

.markYellow {
  background: linear-gradient(transparent 65%, $color-yellow 50%);
}
.markThinYellow {
  background: linear-gradient(transparent 80%, $color-yellow 20%);
}
.markRed {
  background: linear-gradient(transparent 65%, $color-red 50%);
}
.markThinRed {
  background: linear-gradient(transparent 80%, $color-red 20%);
}
