@charset "utf-8";

.l-inner {
  margin: 0 auto;
  padding: 0 $space-unit;
  @include mq-sp {
    padding: 0 $space-unit-sp;
  }
  &PC {
    @include mq-pc {
      margin: 0 auto;
      padding: 0 $space-unit-sp;
    }
  }
  &SP {
    @include mq-sp {
      margin: 0 auto;
      padding: 0 $space-unit-sp;
    }
  }
}

// ベースカラム
.l-column {
  @include column;
  &--noPadding {
    padding: 0;
  }
  &--noPaddingSP {
    @include mq-sp {
      padding: 0;
    }
  }
}

.l-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  &--middle {
    align-items: center;
  }
  &--center {
    justify-content: center;
    &PC {
      @include mq-pc {
        justify-content: center;
      }
    }
    &SP {
      @include mq-sp {
        justify-content: center;
      }
    }
  }
  &--noWrap {
    flex-wrap: nowrap;
    &PC {
      @include mq-pc {
        flex-wrap: nowrap;
      }
    }
  }
  &--re {
    flex-direction: row-reverse;
  }
}

.l-halfTile {
  $root: &;
  @include mq-pc {
    width: 50%;
  }
  @include mq-sp {
    width: 100%;
  }
}

.l-halfBox {
  $root: &;
  @include mq-pc {
    @include halfColumn;
  }
  @include mq-sp {
    @include oneColumn($box-space-sp);
    &--halfColumnSP {
      @include halfColumn($box-space-sp);
    }
  }
  .l-row--re & {
    &:nth-child(1),
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(2) {
      @include mq-pc {
        margin-right: $box-space;
      }
    }
  }
}

.l-tripartitionBox {
  $root: &;
  @include mq-pc {
    @include tripartitionColumn;
  }
  @include mq-sp {
    @include oneColumn($box-space-sp);
  }
}

.l-quarterBox {
  $root: &;
  @include mq-pc {
    @include quarterColumn;
  }
  @include mq-sp {
    @include halfColumn($box-space-sp);
  }
}

.l-fifthBox {
  $root: &;
  @include mq-pc {
    @include fifthColumn;
  }
  @include mq-sp {
    @include halfColumn($box-space-sp);
  }
}

.l-sixthBox {
  $root: &;
  @include mq-pc {
    @include sixthColumn;
  }
  @include mq-sp {
    @include halfColumn($box-space-sp);
  }
}

.l-mw1000px {
  flex: 1 1 1000px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.l-mw900px {
  flex: 1 1 900px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.l-mw800px {
  flex: 1 1 800px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.l-mw700px {
  flex: 1 1 700px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.l-mw600px {
  flex: 1 1 600px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.l-flexZero {
  flex: 0 0 auto;
}
.l-flexMax {
  flex: 1 1 auto;
}
.l-floatLeft {
  @include mq-pc {
    float: left;
    margin-right: $column-space;
    margin-bottom: $column-space;
  }
  @include mq-sp {
    margin-bottom: $column-space-sp;
  }
}
.l-floatRight {
  @include mq-pc {
    float: right;
    margin-left: $column-space;
    margin-bottom: $column-space;
  }
  @include mq-sp {
    margin-bottom: $column-space-sp;
  }
}
////

.l-halfColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.l-halfColumn-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.l-halfColumn-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.l-halfColumnCenter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @include halfColumnCenter;
}
.l-halfColumnCenter-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include halfColumnCenter;
  }
}
.l-halfColumnCenter-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include halfColumnCenter;
  }
}

.l-halfColumnRe {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > *:nth-child(1) {
    order: 2;
  }
}
.l-halfColumnRe-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > *:nth-child(1) {
      order: 2;
    }
  }
}
.l-halfColumnRe-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > *:nth-child(1) {
      order: 2;
    }
  }
}

.l-tripartitionColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.l-tripartitionColumn-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.l-tripartitionColumn-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.l-tripartitionColumnCenter {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @include tripartitionColumnCenter;
}
.l-tripartitionColumnCenter-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @include tripartitionColumnCenter;
  }
}
.l-tripartitionColumnCenter-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @include tripartitionColumnCenter;
  }
}

.l-quarterColumn {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.l-quarterColumn-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
.l-quarterColumn-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.l-quarterColumnCenter {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  @include quarterColumnCenter;
}
.l-quarterColumnCenter-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    @include quarterColumnCenter;
  }
}
.l-quarterColumnCenter-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    @include quarterColumnCenter;
  }
}

.l-fifthColumn {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.l-fifthColumn-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}
.l-fifthColumn-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.l-fifthColumnCenter {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  @include fifthColumnCenter;
}
.l-fifthColumnCenter-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    @include fifthColumnCenter;
  }
}
.l-fifthColumnCenter-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    @include fifthColumnCenter;
  }
}

.l-sixthColumn {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.l-sixthColumn-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}
.l-sixthColumn-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}

.l-sixthColumnCenter {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @include sixthColumnCenter;
}
.l-sixthColumnCenter-mdMin {
  @include mq-pc {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include sixthColumnCenter;
  }
}
.l-sixthColumnCenter-mdMax {
  @include mq-sp {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include sixthColumnCenter;
  }
}
