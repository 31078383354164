@charset "utf-8";

.rollOver {
  transition: opacity .3s;
  img {
    background-color: rgba(255, 255, 255, .01);
  }
  &:hover {
    @include mq-pc {
      opacity: 0.7;
      text-decoration: none;
    }
  }
}
.link {
  &:hover {
    @include mq-pc {
      text-decoration: underline;
    }
  }
}
.linkText {
  text-decoration: underline;
  &:hover {
    @include mq-pc {
      text-decoration: none;
    }
  }
}
//ここまで

.HoverJump {
  $root: &;
  &__item {
    #{$root}:hover & {
      @include mq-pc {
        animation:jump 5s infinite;
      }
    }
  }
  @keyframes jump{
    0% { transform:translate(0,0); }
    3% { transform:translate(0,-15px); }
    5% { transform:translate(0,0); }
    6% { transform:translate(0,-5px); }
    7% { transform:translate(0,0); }
    100% { transform:translate(0,0); }
  }
}
.HoverZoom {
  $root: &;
  &__itemWrap {
    overflow: hidden;
  }
  &__item {
    transition: transform .3s;
    #{$root}:hover & {
      @include mq-pc {
        transform: scale(1.1);
      }
    }
  }
}
.HoverSlide {
  $root: &;
  &__item {
    transition: transform .3s;
    #{$root}:hover & {
      @include mq-pc {
        transform: translateX(10px);
      }
    }
  }
}
