@charset "utf-8";

// :root, html, body {
//   color: $color-main-text;
//   width: 100%;
// }
// * {
//   box-sizing: border-box;
// }
// ul,ol {
//   padding: 0;
//   //  margin: 0;
// }
// li {
//   list-style-type: none;
// }
// a {
//   color: $color-main-text;
//   text-decoration: none;
// }
// a:hover {
//   //  text-decoration: underline;
//   //  color: $color-link;
// }
// html {
//   // sets the base font to 10px for easier math
//   font-size:  62.50%; // 10px
//   line-height: 1.8;
//   @include mq-pcMax {
//     font-size: 56.25%; // 9px
//   }
//   @include mq-sp {
//     font-size: 50.00%; // 8px
//   }
// }
// html[lang="en-US"] {
//   body {
//     @include font-gothic;
//   }
//   button, input, select, textarea {
//     @include font-mincho;
//   }
// }
// body {
//   font-size: 1.8rem; // 18px
//   //  letter-spacing: .05em;
//   @include mq-sp {
//     font-size: $sp-fontSize-16px; // 12px
//   }
// }
// h1,h2,h3,h4,h5,h6,strong {
//   font-weight: 700;
// }
img {
  max-width: 100%;
  height: auto;
}
// figure {
//   margin: 0;
// }
// dd {
//   margin-left: 0;
// }
// hr {
//   margin: $space-unit 0;
//   border: none;
//   border-bottom: 1px solid $color-black;
//   @include mq-sp {
//     margin: $space-unit-sp 0;
//   }
// }
// mark {
//   background: linear-gradient(transparent 60%, $color-yellow 50%);
// }
// *:focus {
//   outline: none;
// }
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}
