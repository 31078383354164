@charset "utf-8";

.u-center {
  text-align: center;
}
.u-centerPC {
  @include mq-pc {
    text-align: center;
  }
}
.u-centerSP {
  @include mq-sp {
    text-align: center;
  }
}
.u-right {
  text-align: right;
}
.u-rightPC {
  @include mq-pc {
    text-align: right;
  }
}
.u-rightSP {
  @include mq-sp {
    text-align: right;
  }
}
.u-left {
  text-align: left;
}
.u-leftPC {
  @include mq-pc {
    text-align: left;
  }
}
.u-leftSP {
  @include mq-sp {
    text-align: left;
  }
}
//svg-fill
.u-fillWhite {
  fill: $color-white;
}
.u-fillBlack {
  fill: $color-black;
}
//font-family
.u-ffGothic {
  //  @include font-gothic;
}
.u-ffMincho {
  //  @include font-mincho;
}
//font-color
.u-fcWhite {
  color: $color-white;
}
.u-fcBlack {
  color: $color-black;
}
.u-fcGray {
  color: $color-gray;
}
.u-fcRed {
  color: $color-red;
}
.u-fcYellow {
  color: $color-yellow;
}

.u-tsRed {
  text-stroke: 1px $color-red;
  -webkit-text-stroke: 1px $color-red;
}
.u-tsBlack {
  text-stroke: 1px $color-black;
  -webkit-text-stroke: 1px $color-black;
}
.u-tsWhite {
  text-stroke: 1px $color-white;
  -webkit-text-stroke: 1px $color-white;
}

//font-size　相対的
.u-fsBig1 {
  font-size: 110%;
}
.u-fsBig2 {
  font-size: 120%;
}
.u-fsBig3 {
  font-size: 130%;
}
.u-fsBig4 {
  font-size: 140%;
}
.u-fsBig5 {
  font-size: 150%;
}
.u-fsBig6 {
  font-size: 160%;
}
.u-fsBig7 {
  font-size: 170%;
}
.u-fsBig8 {
  font-size: 180%;
}
.u-fsBig9 {
  font-size: 190%;
}
.u-fsBig10 {
  font-size: 200%;
}
.u-fsSmall1 {
  font-size: 95%;
}
.u-fsSmall2 {
  font-size: 90%;
}
.u-fsSmall3 {
  font-size: 85%;
}
.u-fsSmall4 {
  font-size: 80%;
}
.u-fsSmall5 {
  font-size: 75%;
}
.u-fsSmall6 {
  font-size: 70%;
}
.u-fsSmall7 {
  font-size: 65%;
}
.u-fsSmall8 {
  font-size: 60%;
}
.u-fsSmall9 {
  font-size: 55%;
}
.u-fsSmall10 {
  font-size: 50%;
}

//
.u-fwRegular, .u-fwNormal {
  font-weight: 400;//Regular
}
.u-fwMedium {
  font-weight: 500;//Medium
}
.u-fwSemiBold {
  font-weight: 600;//Semi-bold
}
.u-fwBold {
  font-weight: 700;//Bold
}
//行間
.u-lhZero {
  line-height: 1.0;
}
.u-lhLow {
  line-height: 1.36;
}
.u-lhMiddle {
  line-height: 1.56;
}
.u-lhHigh {
  line-height: 2.0;
}
.u-lhHighest {
  line-height: 3.0;
}
//
.u-tdUnderLine {
  text-decoration: underline;
}
//
.u-fsItalic {
  font-style: italic;
}
//字間
.u-lsZero {
  letter-spacing: 0;
}
.u-lsShort {
  @include letterSpace-short;
}
.u-lsNormal {
  @include letterSpace-normal;
}
.u-lsLong {
  @include letterSpace-long;
}
//
.u-radiusSmall {
  border-radius: 5px;
  @include mq-sp {
    border-radius: 3px;
  }
}
.u-radiusMedium {
  border-radius: 10px;
  @include mq-sp {
    border-radius: 5px;
  }
}
.u-radiusLarge {
  border-radius: 15px;
  @include mq-sp {
    border-radius: 8px;
  }
}
.u-ofHidden {
  overflow: hidden;
}
.u-vaMiddle {
  vertical-align: middle;
}
.u-dpInlineBlock {
  display: inline-block;
}
.u-dpBlock {
  display: block;
}
.u-dpFlex {
  display: flex;
}
.u-flexAuto {
  flex: 1 1 auto;
}
