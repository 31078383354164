@charset "utf-8";

$exSmallText: $fontSize-11px;
$exSmallText-sp: $fontSize-11px;
.exSmallText {
  font-size: $exSmallText;
  @include mq-sp {
    font-size: $exSmallText-sp;
  }
}
$smallText: $fontSize-12px;
$smallText-sp: $fontSize-12px;
.smallText {
  font-size: $smallText;
  @include mq-sp {
    font-size: $smallText-sp;
  }
}
$mediumText: $fontSize-13px;
$mediumText-sp: $fontSize-13px;
.mediumText {
  font-size: $mediumText;
  @include mq-sp {
    font-size: $mediumText-sp;
  }
}
$normalText: $fontSize-14px;
$normalText-sp: $fontSize-14px;
.normalText {
  font-size: $normalText;
  @include mq-sp {
    font-size: $normalText-sp;
  }
}
$largeText: $fontSize-15px;
$largeText-sp: $fontSize-15px;
.largeText {
  font-size: $largeText;
  @include mq-sp {
    font-size: $largeText-sp;
  }
}
$largeText2: $fontSize-16px;
$largeText2-sp: $fontSize-16px;
.largeText2 {
  font-size: $largeText2;
  @include mq-sp {
    font-size: $largeText2-sp;
  }
}
$largeText3: $fontSize-17px;
$largeText3-sp: $fontSize-17px;
.largeText3 {
  font-size: $largeText3;
  @include mq-sp {
    font-size: $largeText3-sp;
  }
}
$largeText4: $fontSize-18px;
$largeText4-sp: $fontSize-18px;
.largeText4 {
  font-size: $largeText4;
  @include mq-sp {
    font-size: $largeText4-sp;
  }
}
$largeText5: $fontSize-19px;
$largeText5-sp: $fontSize-19px;
.largeText5 {
  font-size: $largeText5;
  @include mq-sp {
    font-size: $largeText5-sp;
  }
}
$exLargeText: $fontSize-20px;
$exLargeText-sp: $fontSize-20px;
.exLargeText {
  font-size: $exLargeText;
  @include mq-sp {
    font-size: $exLargeText-sp;
  }
}
$xxLargeText: $fontSize-25px;
$xxLargeText-sp: $fontSize-25px;
.xxLargeText {
  font-size: $xxLargeText;
  @include mq-sp {
    font-size: $xxLargeText-sp;
  }
}

@include mq-sp {
  .exSmallTextSP {
    font-size: $exSmallText-sp;
  }
  .smallTextSP {
    font-size: $smallText-sp;
  }
  .mediumTextSP {
    font-size: $mediumText-sp;
  }
  .normalTextSP {
    font-size: $normalText-sp;
  }
  .largeTextSP {
    font-size: $largeText-sp;
  }
  .largeText2SP {
    font-size: $largeText2-sp;
  }
  .largeText3SP {
    font-size: $largeText3-sp;
  }
  .largeText4SP {
    font-size: $largeText4-sp;
  }
  .largeText5SP {
    font-size: $largeText5-sp;
  }
  .exLargeTextSP {
    font-size: $exLargeText-sp;
  }
  .xxLargeTextSP {
    font-size: $xxLargeText-sp;
  }
}
