@charset "utf-8";

$auto-space: auto;
$auto-space-sp: auto;
.auto {
  &Box {
    margin: $auto-space;
    @include mq-sp {
      margin: $auto-space-sp;
    }
  }
  &Block {
    margin-top: $auto-space;
    margin-bottom: $auto-space;
    @include mq-sp {
      margin-top: $auto-space-sp;
      margin-bottom: $auto-space-sp;
    }
    &T {
      margin-top: $auto-space;
      @include mq-sp {
        margin-top: $auto-space-sp;
      }
    }
    &B {
      margin-bottom: $auto-space;
      @include mq-sp {
        margin-bottom: $auto-space;
      }
    }
  }
}

$base-space: 60px;
$base-space-sp: 50px;
.base {
  &Box {
    margin: $base-space;
    @include mq-sp {
      margin: $base-space-sp;
    }
  }
  &Block {
    margin-top: $base-space;
    margin-bottom: $base-space;
    @include mq-sp {
      margin-top: $base-space-sp;
      margin-bottom: $base-space-sp;
    }
    &T {
      margin-top: $base-space;
      @include mq-sp {
        margin-top: $base-space-sp;
      }
    }
    &B {
      margin-bottom: $base-space;
      @include mq-sp {
        margin-bottom: $base-space-sp;
      }
    }
  }
}

$section3-space: 180px;
$section3-space-sp: 100px;
.section3 {
  &Box {
    margin: $section3-space;
    @include mq-sp {
      margin: $section3-space-sp;
    }
  }
  &Block {
    margin-top: $section3-space;
    margin-bottom: $section3-space;
    @include mq-sp {
      margin-top: $section3-space-sp;
      margin-bottom: $section3-space-sp;
    }
    &T {
      margin-top: $section3-space;
      @include mq-sp {
        margin-top: $section3-space-sp;
      }
    }
    &B {
      margin-bottom: $section3-space;
      @include mq-sp {
        margin-bottom: $section3-space-sp;
      }
    }
  }
}

$section2-space: 120px;
$section2-space-sp: 75px;
.section2 {
  &Box {
    margin: $section2-space;
    @include mq-sp {
      margin: $section2-space-sp;
    }
  }
  &Block {
    margin-top: $section2-space;
    margin-bottom: $section2-space;
    @include mq-sp {
      margin-top: $section2-space-sp;
      margin-bottom: $section2-space-sp;
    }
    &T {
      margin-top: $section2-space;
      @include mq-sp {
        margin-top: $section2-space-sp;
      }
    }
    &B {
      margin-bottom: $section2-space;
      @include mq-sp {
        margin-bottom: $section2-space-sp;
      }
    }
  }
}

$section-space: 60px;
$section-space-sp: 50px;
.section {
  &Box {
    margin: $section-space;
    @include mq-sp {
      margin: $section-space-sp;
    }
  }
  &Block {
    margin-top: $section-space;
    margin-bottom: $section-space;
    @include mq-sp {
      margin-top: $section-space-sp;
      margin-bottom: $section-space-sp;
    }
    &T {
      margin-top: $section-space;
      @include mq-sp {
        margin-top: $section-space-sp;
      }
    }
    &B {
      margin-bottom: $section-space;
      @include mq-sp {
        margin-bottom: $section-space-sp;
      }
    }
  }
}

$contents-space: 40px;
$contents-space-sp: 30px;
.contents {
  &Box {
    margin: $contents-space;
    @include mq-sp {
      margin: $contents-space-sp;
    }
  }
  &Block {
    margin-top: $contents-space;
    margin-bottom: $contents-space;
    @include mq-sp {
      margin-top: $contents-space-sp;
      margin-bottom: $contents-space-sp;
    }
    &T {
      margin-top: $contents-space;
      @include mq-sp {
        margin-top: $contents-space-sp;
      }
    }
    &B {
      margin-bottom: $contents-space;
      @include mq-sp {
        margin-bottom: $contents-space-sp;
      }
    }
    &L {
      margin-left: $contents-space;
      @include mq-sp {
        margin-left: $contents-space-sp;
      }
    }
    &R {
      margin-right: $contents-space;
      @include mq-sp {
        margin-right: $contents-space-sp;
      }
    }
  }
}

$normal-space: 30px;
$normal-space-sp: 20px;
.normal {
  &Box {
    margin: $normal-space;
    @include mq-sp {
      margin: $normal-space-sp;
    }
  }
  &Block {
    margin-top: $normal-space;
    margin-bottom: $normal-space;
    @include mq-sp {
      margin-top: $normal-space-sp;
      margin-bottom: $normal-space-sp;
    }
    &T {
      margin-top: $normal-space;
      @include mq-sp {
        margin-top: $normal-space-sp;
      }
    }
    &B {
      margin-bottom: $normal-space;
      @include mq-sp {
        margin-bottom: $normal-space-sp;
      }
    }
    &L {
      margin-left: $normal-space;
      @include mq-sp {
        margin-left: $normal-space-sp;
      }
    }
    &R {
      margin-right: $normal-space;
      @include mq-sp {
        margin-right: $normal-space-sp;
      }
    }
  }
}

$semi-space: 20px;
$semi-space-sp: 15px;
.semi {
  &Box {
    margin: $semi-space;
    @include mq-sp {
      margin: $semi-space-sp;
    }
  }
  &Block {
    margin-top: $semi-space;
    margin-bottom: $semi-space;
    @include mq-sp {
      margin-top: $semi-space-sp;
      margin-bottom: $semi-space-sp;
    }
    &T {
      margin-top: $semi-space;
      @include mq-sp {
        margin-top: $semi-space-sp;
      }
    }
    &B {
      margin-bottom: $semi-space;
      @include mq-sp {
        margin-bottom: $semi-space-sp;
      }
    }
    &L {
      margin-left: $semi-space;
      @include mq-sp {
        margin-left: $semi-space-sp;
      }
    }
    &R {
      margin-right: $semi-space;
      @include mq-sp {
        margin-right: $semi-space-sp;
      }
    }
  }
}

$small-space: 15px;
$small-space-sp: 10px;
.small {
  &Box {
    margin: $small-space;
    @include mq-sp {
      margin: $small-space-sp;
    }
  }
  &Block {
    margin-top: $small-space;
    margin-bottom: $small-space;
    @include mq-sp {
      margin-top: $small-space-sp;
      margin-bottom: $small-space-sp;
    }
    &T {
      margin-top: $small-space;
      @include mq-sp {
        margin-top: $small-space-sp;
      }
    }
    &B {
      margin-bottom: $small-space;
      @include mq-sp {
        margin-bottom: $small-space-sp;
      }
    }
    &L {
      margin-left: $small-space;
      @include mq-sp {
        margin-left: $small-space-sp;
      }
    }
    &R {
      margin-right: $small-space;
      @include mq-sp {
        margin-right: $small-space-sp;
      }
    }
  }
}

$mini-space: 10px;
$mini-space-sp: 10px;
.mini {
  &Box {
    margin: $mini-space;
    @include mq-sp {
      margin: $mini-space-sp;
    }
  }
  &Block {
    margin-top: $mini-space;
    margin-bottom: $mini-space;
    @include mq-sp {
      margin-top: $mini-space-sp;
      margin-bottom: $mini-space-sp;
    }
    &T {
      margin-top: $mini-space;
      @include mq-sp {
        margin-top: $mini-space-sp;
      }
    }
    &B {
      margin-bottom: $mini-space;
      @include mq-sp {
        margin-bottom: $mini-space-sp;
      }
    }
    &L {
      margin-left: $mini-space;
      @include mq-sp {
        margin-left: $mini-space-sp;
      }
    }
    &R {
      margin-right: $mini-space;
      @include mq-sp {
        margin-right: $mini-space-sp;
      }
    }
  }
}

$minimum-space: 5px;
$minimum-space-sp: 5px;
.minimum {
  &Box {
    margin: $minimum-space;
    @include mq-sp {
      margin: $minimum-space-sp;
    }
  }
  &Block {
    margin-top: $minimum-space;
    margin-bottom: $minimum-space;
    @include mq-sp {
      margin-top: $minimum-space-sp;
      margin-bottom: $minimum-space-sp;
    }
    &T {
      margin-top: $minimum-space;
      @include mq-sp {
        margin-top: $minimum-space-sp;
      }
    }
    &B {
      margin-bottom: $minimum-space;
      @include mq-sp {
        margin-bottom: $minimum-space-sp;
      }
    }
    &L {
      margin-left: $minimum-space;
      @include mq-sp {
        margin-left: $minimum-space-sp;
      }
    }
    &R {
      margin-right: $minimum-space;
      @include mq-sp {
        margin-right: $minimum-space-sp;
      }
    }
  }
}

$zero-space: 0;
$zero-space-sp: 0;
.zero {
  &Box {
    margin: $zero-space;
    @include mq-sp {
      margin: $zero-space-sp;
    }
  }
  &Block {
    margin-top: $zero-space;
    margin-bottom: $zero-space;
    @include mq-sp {
      margin-top: $zero-space-sp;
      margin-bottom: $zero-space-sp;
    }
    &T {
      margin-top: $zero-space;
      @include mq-sp {
        margin-top: $zero-space-sp;
      }
    }
    &B {
      margin-bottom: $zero-space;
      @include mq-sp {
        margin-bottom: $zero-space-sp;
      }
    }
  }
}

/*
@include mq-pc {
  .basePC {
    &Box {
      margin: $base-space;
    }
    &Block {
      margin-top: $base-space;
      margin-bottom: $base-space;
      &T {
        margin-top: $base-space;
      }
      &B {
        margin-bottom: $base-space;
      }
      &L {
        margin-left: $base-space;
      }
      &R {
        margin-right: $base-space;
      }
    }
  }
  .section2PC {
    &Box {
      margin: $section2-space;
    }
    &Block {
      margin-top: $section2-space;
      margin-bottom: $section2-space;
      &T {
        margin-top: $section2-space;
      }
      &B {
        margin-bottom: $section2-space;
      }
      &L {
        margin-left: $section2-space;
      }
      &R {
        margin-right: $section2-space;
      }
    }
  }
  .sectionPC {
    &Box {
      margin: $section-space;
    }
    &Block {
      margin-top: $section-space;
      margin-bottom: $section-space;
      &T {
        margin-top: $section-space;
      }
      &B {
        margin-bottom: $section-space;
      }
      &L {
        margin-left: $section-space;
      }
      &R {
        margin-right: $section-space;
      }
    }
  }
  .contentsPC {
    &Box {
      margin: $contents-space;
    }
    &Block {
      margin-top: $contents-space;
      margin-bottom: $contents-space;
      &T {
        margin-top: $contents-space;
      }
      &B {
        margin-bottom: $contents-space;
      }
      &L {
        margin-left: $contents-space;
      }
      &R {
        margin-right: $contents-space;
      }
    }
  }
  .normalPC {
    &Box {
      margin: $normal-space;
    }
    &Block {
      margin-top: $normal-space;
      margin-bottom: $normal-space;
      &T {
        margin-top: $normal-space;
      }
      &B {
        margin-bottom: $normal-space;
      }
      &L {
        margin-left: $normal-space;
      }
      &R {
        margin-right: $normal-space;
      }
    }
  }
  .miniPC {
    &Box {
      margin: $mini-space;
    }
    &Block {
      margin-top: $mini-space;
      margin-bottom: $mini-space;
      &T {
        margin-top: $mini-space;
      }
      &B {
        margin-bottom: $mini-space;
      }
      &L {
        margin-left: $mini-space;
      }
      &R {
        margin-right: $mini-space;
      }
    }
  }
  .semiPC {
    &Box {
      margin: $semi-space;
    }
    &Block {
      margin-top: $semi-space;
      margin-bottom: $semi-space;
      &T {
        margin-top: $semi-space;
      }
      &B {
        margin-bottom: $semi-space;
      }
      &L {
        margin-left: $semi-space;
      }
      &R {
        margin-right: $semi-space;
      }
    }
  }
  .minimumPC {
    &Box {
      margin: $minimum-space;
    }
    &Block {
      margin-top: $minimum-space;
      margin-bottom: $minimum-space;
      &T {
        margin-top: $minimum-space;
      }
      &B {
        margin-bottom: $minimum-space;
      }
      &L {
        margin-left: $minimum-space;
      }
      &R {
        margin-right: $minimum-space;
      }
    }
  }
  .zeroPC {
    &Box {
      margin: $zero-space;
    }
    &Block {
      margin-top: $zero-space;
      margin-bottom: $zero-space;
      &T {
        margin-top: $zero-space;
      }
      &B {
        margin-bottom: $zero-space;
      }
    }
  }
}
*/

@include mq-sp {
  .baseSP {
    &Box {
      margin: $base-space-sp;
    }
    &Block {
      margin-top: $base-space-sp;
      margin-bottom: $base-space-sp;
      &T {
        margin-top: $base-space-sp;
      }
      &B {
        margin-bottom: $base-space-sp;
      }
      &L {
        margin-left: $base-space-sp;
      }
      &R {
        margin-right: $base-space-sp;
      }
    }
  }
  .section2SP {
    &Box {
      margin: $section2-space-sp;
    }
    &Block {
      margin-top: $section2-space-sp;
      margin-bottom: $section2-space-sp;
      &T {
        margin-top: $section2-space-sp;
      }
      &B {
        margin-bottom: $section2-space-sp;
      }
      &L {
        margin-left: $section2-space-sp;
      }
      &R {
        margin-right: $section2-space-sp;
      }
    }
  }
  .sectionSP {
    &Box {
      margin: $section-space-sp;
    }
    &Block {
      margin-top: $section-space-sp;
      margin-bottom: $section-space-sp;
      &T {
        margin-top: $section-space-sp;
      }
      &B {
        margin-bottom: $section-space-sp;
      }
      &L {
        margin-left: $section-space-sp;
      }
      &R {
        margin-right: $section-space-sp;
      }
    }
  }
  .contentsSP {
    &Box {
      margin: $contents-space-sp;
    }
    &Block {
      margin-top: $contents-space-sp;
      margin-bottom: $contents-space-sp;
      &T {
        margin-top: $contents-space-sp;
      }
      &B {
        margin-bottom: $contents-space-sp;
      }
      &L {
        margin-left: $contents-space-sp;
      }
      &R {
        margin-right: $contents-space-sp;
      }
    }
  }
  .normalSP {
    &Box {
      margin: $normal-space-sp;
    }
    &Block {
      margin-top: $normal-space-sp;
      margin-bottom: $normal-space-sp;
      &T {
        margin-top: $normal-space-sp;
      }
      &B {
        margin-bottom: $normal-space-sp;
      }
      &L {
        margin-left: $normal-space-sp;
      }
      &R {
        margin-right: $normal-space-sp;
      }
    }
  }
  .miniSP {
    &Box {
      margin: $mini-space-sp;
    }
    &Block {
      margin-top: $mini-space-sp;
      margin-bottom: $mini-space-sp;
      &T {
        margin-top: $mini-space-sp;
      }
      &B {
        margin-bottom: $mini-space-sp;
      }
      &L {
        margin-left: $mini-space-sp;
      }
      &R {
        margin-right: $mini-space-sp;
      }
    }
  }
  .semiSP {
    &Box {
      margin: $semi-space-sp;
    }
    &Block {
      margin-top: $semi-space-sp;
      margin-bottom: $semi-space-sp;
      &T {
        margin-top: $semi-space-sp;
      }
      &B {
        margin-bottom: $semi-space-sp;
      }
      &L {
        margin-left: $semi-space-sp;
      }
      &R {
        margin-right: $semi-space-sp;
      }
    }
  }
  .minimumSP {
    &Box {
      margin: $minimum-space-sp;
    }
    &Block {
      margin-top: $minimum-space-sp;
      margin-bottom: $minimum-space-sp;
      &T {
        margin-top: $minimum-space-sp;
      }
      &B {
        margin-bottom: $minimum-space-sp;
      }
      &L {
        margin-left: $minimum-space-sp;
      }
      &R {
        margin-right: $minimum-space-sp;
      }
    }
  }
  .zeroSP {
    &Box {
      margin: $zero-space-sp;
    }
    &Block {
      margin-top: $zero-space-sp;
      margin-bottom: $zero-space-sp;
      &T {
        margin-top: $zero-space-sp;
      }
      &B {
        margin-bottom: $zero-space-sp;
      }
    }
  }
}
