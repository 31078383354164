@charset "utf-8";

.bgColorWhite {
	background-color: $color-white;
}
.bgColorBlack {
	background-color: $color-black;
}
.bgColorBase {
	background-color: $color-base;
}
.bgColorGray {
	background-color: $color-lightGray;
}

.bgImage {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
