@charset "utf-8";

/*
* ---------- フッター -----------
*/
.area_footer {
  background: #45b9be;
  color: #fff;
}
.area_footer .logo_area .logo {
  font-weight: bold;
}

.area_footer {
  .area_cv {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: #45b9be;
    width: 100%;
    z-index: 5;
    @include mq-pc {
      transition: transform .3s;
      background: none;
    }
    .cv_list {
      display: flex;
      @include mq-pc {
        justify-content: center;
      }
      .cv {
        position: relative;
        max-width: calc(100% / 4);
        flex: 1 1 25%;
        border: 1px solid #333;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.5);
        @include mq-pc {
          transition: .3s;
          max-width: 250px;
        }
        &:hover {
          @include mq-sp {
            top: 3px;
            box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5);
          }
        }
        a {
          justify-content: center;
          align-items: center;
          font-size: 12px;
          line-height: 1.5;
          padding: 15px 0;
          @include mq-pc {
            font-size: 15px;
            br {
              display: none;
            }
          }
          .cv_txt {
            position: relative;
            padding-left: 10px;
            &:after {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0, -50%);
              content: "";
              width: 6px;
              height: 10px;
              background: #fff;
              clip-path: polygon(0 0, 100% 50%, 0 100%, 0 0);
            }
          }
        }
      }
    }
    @include mq-pc {
      transform: translate(-50%,100%);
    }
    &.is-active {
      @include mq-pc {
        transform: translate(-50%,0);
      }
    }
  }
  .cv_flow {
    background: #fb6385;
    &:hover {
      @include mq-pc {
        color: #fb6385;
        background-color: $color-white;
      }
    }
  }
  .cv_doc {
    background: #f8b551;
    &:hover {
      @include mq-pc {
        color: #f8b551;
        background-color: $color-white;
      }
    }
  }
  .cv_contact {
    background: #448aca;
    &:hover {
      @include mq-pc {
        color: #448aca;
        background-color: $color-white;
      }
    }
  }
  .cv_loan {
    background: #85B200;
    &:hover {
      @include mq-pc {
        color: #85B200;
        background-color: $color-white;
      }
    }
  }
}

@include mq-sp {
  .area_footer {
    .content_inner {
      padding: 50px 0 100px;
    }
    .logo_area .logo {
      font-size: 2em;
      padding: 10px 0;
    }
    .copy {
      font-size: 12px;
    }
  }
}
@include mq-pc {
  .area_footer .content_inner {
    padding: 50px 0 150px;
  }
  .area_footer .logo_area .sub_txt {
    font-size: 1.5em;
  }
  .area_footer .logo_area .logo {
    font-size: 3em;
  }
}



@include mq-sp2 {
  .area_footer .area_cv .cv_list .cv a {
    font-size: 10px;
    padding: 12.5px 0;
  }

  .area_map_box .area_list .area .btn_box .btn_txt {
    font-size: 0.8em;
  }
  .area_map_box .area_list .area.area_nagoya .btn_box {
    width: 65px;
  }
  .area_map_box .area_list .area.area_hyogo_osaka {
    width: 28vw;
  }
  .area_map_box .area_list .area.area_hyogo_osaka .btn_box {
    width: 85%;
  }
  .area_map_box .area_list .area.area_fukuoka .btn_box {
    width: 55px;
  }

  .sec_heim_list .heim_list .heim .txt_box .room_info .info_title {
    font-size: 10px;
    margin-right: 5px;
  }
  .sec_heim_list .heim_list .heim .txt_box .room_info .info_content {
    width: 70%;
    font-size: 10px;
  }
  .sec_heim_list .heim_list .heim .form_notes {
    font-size: 10px;
  }

  .area_basic_info .area_floor_img .floor_img_list .floor_img {
    width: calc(100% / 5.5);
  }
}
