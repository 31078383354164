@charset "utf-8";

@each $name, $spaceValue in $spaceValues {
  // '-space'を取り除いた名前を生成
  $short-name: str-slice($name, 1, str-index($name, '-space') - 1);

  .gap-#{$short-name} {
    @include mq-pc {
      gap: $spaceValue * 1px;
    }
  }
  .gap-#{$short-name}-mdMin {
    @include mq-pc {
      gap: $spaceValue * 1px;
    }
  }
}

@each $name, $spaceValue in $spaceValues-mdMax {
  // '-space'を取り除いた名前を生成
  $short-name: str-slice($name, 1, str-index($name, '-space') - 1);

  .gap-#{$short-name} {
    @include mq-sp {
      gap: $spaceValue * 1px;
    }
  }
  .gap-#{$short-name}-mdMax {
    @include mq-sp {
      gap: $spaceValue * 1px;
    }
  }
}
