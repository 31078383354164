@charset "utf-8";

/*
* ---------- ヘッダー -----------
*/
.area_menu .menu a {
  color: #fff;
}
.area_menu .menu.menu_area {
  position: relative;
  background: #fff;
  border-radius: 90px;
  box-shadow: 2px 4px 0 0 rgba(0, 0, 0, 0.2);
}
.area_menu .menu.menu_area a {
  color: #333;
  font-weight: bold;
}
.area_menu .menu.menu_area:after {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  content: "";
  clip-path: polygon(0 0, 100% 50%, 0 100%, 0 0);
}
.area_menu .menu.menu_area.area_hokkaido:after {
  background: #089488;
}
.area_menu .menu.menu_area.area_sendai:after {
  background: #86bf23;
}
.area_menu .menu.menu_area.area_tokyo:after {
  background: #efe700;
}
.area_menu .menu.menu_area.area_nagoya:after {
  background: #007ac2;
}
.area_menu .menu.menu_area.area_osaka:after {
  background: #ec6a46;
}
.area_menu .menu.menu_area.area_hyogo:after {
  background: #f6b429;
}
.area_menu .menu.menu_area.area_fukuoka:after {
  background: #e95faa;
}
.area_menu .menu.menu_area:hover {
  top: 2px;
  box-shadow: 2px 1px 0 0 rgba(0, 0, 0, 0.2);
}

.menu_list {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-basis: auto;
}
.menu_list.menutype_page .menu {
  position: relative;
}
.menu_list.menutype_page .menu.menu_cv {
  border: 1px solid #333;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
}
.menu_list.menutype_page .menu.menu_cv:after {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  content: "";
  background: #fff;
  clip-path: polygon(0 0, 100% 50%, 0 100%, 0 0);
}
.cv_document {
  background: #f8b551;
}
.cv_contact {
  background: #448aca;
}
.cv_loan {
  background: $color-green;
}
.menu_list.menutype_page .menu.menu_cv:hover {
  top: 2px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
}

.area_breadcrumb_list {
  position: relative;
  background: #eee;
  z-index: 10;
}
.area_breadcrumb_list .breadcrumb_list .bread {
  color: #666;
}
.area_breadcrumb_list .breadcrumb_list .bread + .bread {
  position: relative;
}
.area_breadcrumb_list .breadcrumb_list .bread + .bread:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: bold;
  color: #666;
}
.area_breadcrumb_list a {
  color: #666;
  text-decoration: underline;
}
.area_breadcrumb_list a:hover {
  opacity: 0.8;
  text-decoration: none;
}



@include mq-pc2Max {
  .area_header .area_menu_list .menu_list.menutype_page {
    width: calc( 100% / 2);
  }
  .area_header .area_menu_list .menu_list.menutype_page .menu {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
  }
  .area_header .area_menu_list .menu_list.menutype_page .menu a {
    width: 100%;
    height: 100%;
    line-height: 1.2;
  }
  .area_header .area_menu_list .menu_list.menutype_page .menu.menu_cv:not(:first-child) {
    margin-right: 0px;
  }
  .area_header .area_menu_list .menu_list.menutype_page .menu.menu_cv:after {
    left: 5px;
  }
  .area_header .area_menu_list .menu_list.menutype_area .menu.menu_area:after {
    left: 5px;
  }
  .area_header .area_menu_list .menu_list.menutype_area .menu.menu_area a {
    font-size: 1.25em;
  }
}




@include mq-pc {
  .area_header {
    .header_logo_boc {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      flex-basis: auto;
      .area_logo {
        padding: 20px 0;
        flex: 0 0 280px;
        max-width: 280px;
        margin-right: 50px;
      }
      &__main {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: calc(100% - 330px);
      }
    }
  }

  .menu_list {
    padding: 10px 0;
    flex: 0 1 auto;
    &.menutype_area {
      justify-content: space-between;
      .menu {
        a {
          display: flex;
          flex-wrap: wrap;
          flex-shrink: 0;
          flex-basis: auto;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-align: center;
          font-weight: bold;
          font-size: 1.5em;
          padding: 5px 0;
        }
        &:first-child a {
          padding: 5px 20px;
        }
        &.menu_area {
          width: calc(100% / 8.25);
          &:after {
            left: 20px;
            width: 8px;
            height: 14px;
          }
        }
      }
    }
    &.menutype_page {
      .menu {
        position: relative;
        margin: 0 5px;
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
          width: 0;
          height: 0;
          background: #daf1f2;
          border-radius: 50%;
          transition: .3s;
          z-index: 1;
        }
        a {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          flex-shrink: 0;
          flex-basis: auto;
          justify-content: center;
          color: #333;
          font-weight: bold;
          padding: 10px 5px;
          z-index: 2;
        }
        &.menu_info {
          &:hover {
            a {
              color: #45b9be;
            }
          }
        }
        &.menu_cv {
          &:not(:first-child) {
            margin-left: 10px;
            margin-right: 0;
          }
          &:after {
            width: 8px;
            height: 14px;
          }
          a {
            color: #fff;
            padding-left: 25px;
            padding-right: 10px;
          }
        }
        &.menu_page {
          a {
            font-size: 1.1em;
          }
          &:hover {
            &:after {
              width: 50px;
              height: 50px;
            }
            a {
              color: #45b9be;
            }
          }
        }
        &:not(.menu_cv):hover {
          opacity: 0.7;
        }
      }
    }
  }

  .area_menu_list {
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .area_menu_list .area_menu {
    background: #45b9be;
  }

  .area_breadcrumb_list {
    padding: 5px 0;
  }
  .area_breadcrumb_list .breadcrumb_list {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 1200px;
    margin: 0 auto;
  }
  .area_breadcrumb_list .breadcrumb_list .bread + .bread {
    margin-left: 10px;
    padding-left: 15px;
  }
}


@include mq-sp {
  .area_header {
    position: sticky;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 100;
  }
  .area_header .header_logo_boc {
    position: relative;
    background: #fff;
    z-index: 5;
  }
  .area_header .area_logo .logo {
    width: 50%;
  }
  .area_header .menu_icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20vw;
    height: 20vw;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
    z-index: 9;
    padding: 10px 20px;
  }
  .area_header .menu_icon:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    border-radius: 50%;
    width: 150%;
    height: 150%;
    background: #adebfe;
    transition: .3s;
    z-index: 1;
  }
  .area_header .menu_icon .bar, .area_header .menu_icon .bar_txt {
    position: relative;
    line-height: 1;
    font-weight: bold;
    z-index: 2;
  }
  .area_header .menu_icon .bar {
    width: 100%;
    height: 4px;
    background: #595757;
    transition: .3s;
    margin: 15px 0;
  }
  .area_header .menu_icon .bar:before, .area_header .menu_icon .bar:after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 4px;
    background: #595757;
    transition: .3s;
  }
  .area_header .menu_icon .bar:before {
    top: -12px;
  }
  .area_header .menu_icon .bar:after {
    bottom: -12px;
  }
  .area_header .menu_icon .bar_txt {
    align-self: flex-start;
  }
  .area_header .area_menu {
    position: fixed;
    top: -100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 80vh;
    background: #45b9be;
    transition: .3s;
    z-index: 4;
    overflow-y: scroll;
    padding: 70px 20px 20px;
  }
  .area_header .area_menu .content_inner {
    padding: 10px 0;
  }
  .area_header .area_menu .menu_list {
    justify-content: space-between;
    margin-top: 20px;
  }
  .area_header .area_menu .menu_list .menu {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #fff;
  }
  .area_header .area_menu .menu_list .menu.menu_page:after {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: bold;
    color: #fff;
  }
  .area_header .area_menu .menu_list .menu a {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: auto;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1.15em;
    font-weight: bold;
    padding: 10px;
  }
  .area_header .area_menu .menu_list .menu.menu_title {
    color: #fff;
    font-size: 1.25em;
    font-weight: bold;
    border-bottom: none;
    padding: 10px 10px 5px;
  }
  .area_header .area_menu .menu_list .menu.menu_area {
    width: calc(100% / 2.25);
    margin: 5px 0;
  }
  .area_header .area_menu .menu_list .menu.menu_area:after {
    left: 10px;
    width: 8px;
    height: 14px;
  }
  .area_header .area_menu .menu_list .menu.menu_area a {
    padding: 5px 10px 5px 25px;
  }
  .area_header .area_menu .menu_list .menu.menu_cv {
    width: calc(100% / 2.1);
    margin: 25px 0;
  }
  .area_header .area_menu .menu_list .menu.menu_cv:after {
    width: 8px;
    height: 14px;
  }
  .area_header .area_menu .menu_list .menu.menu_cv a {
    justify-content: center;
    line-height: 1.2;
    padding: 25px 10px 25px 25px;
  }
  .area_header .area_menu .menu_list .menu.menu_info {
    border: none;
    padding-left: 15px;
  }
  .area_header .area_menu .menu_list .menu.menu_info a {
    font-size: 14px;
    padding: 2px 10px;
  }
  .area_header .area_menu .menu_list .menu.menu_info:after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: bold;
    width: 15px;
    height: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    line-height: 1;
    padding-left: 2px;
  }
  .area_header.open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 16;
  }
  .area_header.open .area_menu_list {
    height: 80vh;
  }
  .area_header.open .menu_icon .bar {
    background: #adebfe;
  }
  .area_header.open .menu_icon .bar:before {
    top: 0;
    width: 100%;
    transform: rotate(35deg) translate(0, -50%);
    transform-origin: center;
  }
  .area_header.open .menu_icon .bar:after {
    bottom: 0;
    width: 100%;
    transform: rotate(-35deg) translate(0, -50%);
    transform-origin: center;
  }
  .area_header.open .area_menu {
    top: 0;
  }
  .area_header .area_breadcrumb_list {
    padding: 2px 10px;
  }
  .open.area_header .area_breadcrumb_list {
    display: none;
  }
  .area_header .area_breadcrumb_list .breadcrumb_list {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    font-size: 12px;
  }
  .area_header .area_breadcrumb_list .breadcrumb_list .bread + .bread {
    margin-left: 10px;
    padding-left: 15px;
  }
}

@include mq-sp2 {
  .area_header.open .menu_icon .bar {
    left: -3px;
  }
  .area_header.open .menu_icon .bar:before {
    width: 120%;
  }
  .area_header.open .menu_icon .bar:after {
    width: 120%;
  }
  .area_header .area_menu .menu_list .menu.menu_cv a {
    font-size: 1.1em;
    padding: 15px 10px 15px 25px;
  }
}
