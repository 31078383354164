@charset "utf-8";

//color
$color-black: #000; //
$color-white: #fff; //

$color-orange: #f8b551;
$color-blue: #448aca;
$color-green: #85B200;
$color-yellow: #FFC700; //
$color-red: #c00; //

$color-base: #45b9be; //

$color-hokkaido: #089488;
$color-sendai: #86bf23;
$color-tokyo: #efe700;
$color-nagoya: #007ac2;
$color-osaka: #ec6a46;
$color-hyogo: #f6b429;
$color-fukuoka: #e95faa;

$color-twitter: rgb(29,161,242); //
$color-instagram: #b900b4; //
$color-facebook: #385898; //


$color-gray: #A6A9AB; //
$color-gray2: #979797; //
$color-gray3: #4F4949; //
$color-lightGray: #F2F2F2; //
$color-lightGray2: #D8D8D8; //

/////

//スペース
$space-unit: 20px; //
$space-unit-sp: 20px; //
$box-space: 20px;
$box-space-sp: 20px;
//
$column-space: 60px;
$column-space-sp: 30px;
//基本的なカラムの幅
//$column-width-short: 1040px;
$column-width: 1200px; //
//$column-width-long: 1320px;

//基本的なカラムの幅に余白を追加
//$base-content-width-short: $column-width-short + $space-unit*2;
$base-content-width: $column-width + $space-unit*2; //
//$base-content-width-long: $column-width-long + $space-unit*2;

//Header Height
$header-height: 50px;
$header-height-sp: 50px;
//Footer Nav Height //$footer-height-sp: 60px;
$scroll-bar: 17px; //17pxと仮定

//break point
$pc-min-width: 768px;
$sp-max-width: 767px;
$pc2-min-width: 1040px;
$pc-max-width: $pc2-min-width - 1; //1039
$pc2-max-width: $column-width; //$base-content-width + $scroll-bar;
$pc3-min-width: $pc2-max-width + 1; //1258

//font-sizes 14px * 100.00%
$fontSize-base: .71429rem; //10px
$fontSize-8px: $fontSize-base*0.8;
$fontSize-9px: $fontSize-base*0.9;
$fontSize-10px: $fontSize-base;
$fontSize-11px: $fontSize-base*1.1;
$fontSize-12px: $fontSize-base*1.2;
$fontSize-13px: $fontSize-base*1.3;
$fontSize-14px: $fontSize-base*1.4;
$fontSize-15px: $fontSize-base*1.5;
$fontSize-16px: $fontSize-base*1.6;
$fontSize-17px: $fontSize-base*1.7;
$fontSize-18px: $fontSize-base*1.8;
$fontSize-19px: $fontSize-base*1.9;
$fontSize-20px: $fontSize-base*2.0;
$fontSize-21px: $fontSize-base*2.1;
$fontSize-22px: $fontSize-base*2.2;
$fontSize-23px: $fontSize-base*2.3;
$fontSize-24px: $fontSize-base*2.4;
$fontSize-25px: $fontSize-base*2.5;
$fontSize-26px: $fontSize-base*2.6;
$fontSize-27px: $fontSize-base*2.7;
$fontSize-28px: $fontSize-base*2.8;
$fontSize-29px: $fontSize-base*2.9;
$fontSize-30px: $fontSize-base*3.0;
$fontSize-31px: $fontSize-base*3.1;
$fontSize-32px: $fontSize-base*3.2;
$fontSize-33px: $fontSize-base*3.3;
$fontSize-34px: $fontSize-base*3.4;
$fontSize-35px: $fontSize-base*3.5;
$fontSize-36px: $fontSize-base*3.6;
$fontSize-37px: $fontSize-base*3.7;
$fontSize-38px: $fontSize-base*3.8;
$fontSize-39px: $fontSize-base*3.9;
$fontSize-40px: $fontSize-base*4.0;
$fontSize-41px: $fontSize-base*4.1;
$fontSize-42px: $fontSize-base*4.2;
$fontSize-43px: $fontSize-base*4.3;
$fontSize-44px: $fontSize-base*4.4;
$fontSize-45px: $fontSize-base*4.5;
$fontSize-46px: $fontSize-base*4.6;
$fontSize-47px: $fontSize-base*4.7;
$fontSize-48px: $fontSize-base*4.8;
$fontSize-49px: $fontSize-base*4.9;
$fontSize-50px: $fontSize-base*5.0;
////
$spaceValues: (
  section03-space: 150,
  section02-space: 125,
  section-space: 100,
  contents06-space: 90,
  contents05-space: 80,
  contents04-space: 70,
  contents03-space: 60,
  contents02-space: 50,
  contents-space: 40,
  normal-space: 30,
  semi-space: 20,
  small-space: 15,
  mini-space: 10,
  minimum-space: 5,
  zero-space: 0,
);
$spaceValues-mdMax: (
section03-space: 80,
section02-space: 70,
section-space: 60,
contents06-space: 55,
contents05-space: 50,
contents04-space: 45,
contents03-space: 40,
contents02-space: 35,
contents-space: 30,
normal-space: 25,
semi-space: 20,
small-space: 15,
mini-space: 10,
minimum-space: 5,
zero-space: 0,
);
//paths
$image-path: "../images/";
$font-path: "../fonts/";
//animationClass
$class-active: ".is-active";
$class-current: ".is-current";
$class-fixed: ".is-fixed";
$class-show: ".is-show";
$data-anime: "[data-animation="true"]";
